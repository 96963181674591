<template>
  <div class="ms-auto" v-if="workflowKeys.length > 0">
    <workflow-button-single
      v-for="(status, index) in workflowKeys"
      :key="`${status[0]}_${index}`"
      :buttonContent="status[0]"
      :buttonsInfo="buttonsInfo"
      :action="openStatusChangeModal"
      :disabled="!status[1]"
    />
    <modal-status-change
      id="statusChangeModal"
      :status="selectedStatus"
      :label="selectedStatusLabel"
      :action="modalChangeAction"
    />
  </div>
</template>

<script>
const WorkflowButtonSingle = () => import('./WorkflowButtonSingle.vue');
const ModalStatusChange = () => import('./ModalStatusChange.vue');

export default {
  name: 'WorkflowButtons',
  components: { WorkflowButtonSingle, ModalStatusChange },
  props: {
    workflow: Object,
    buttonsInfo: Object,
    changeAction: Function,
  },
  data() {
    return {
      selectedStatus: null,
      selectedStatusLabel: '',
    };
  },
  computed: {
    workflowKeys() {
      // console.log(
      //   'Object.keys(this.workflow)',
      //   Object.keys(this.workflow),
      //   Object.entries(this.workflow),
      // );
      // return Object.keys(this.workflow);
      return Object.entries(this.workflow);
    },
  },
  methods: {
    openStatusChangeModal(status) {
      // console.log('changing status', status);
      this.selectedStatus = status;
      const a = this.buttonsInfo[status];
      if (a?.label) {
        this.selectedStatusLabel = a.label;
      } else {
        this.selectedStatusLabel = status;
      }
      this.$bvModal.show('statusChangeModal');
    },
    modalChangeAction(note) {
      // console.log('calling change status action');
      this.changeAction(this.selectedStatus, note);
    },
  },
};
</script>
